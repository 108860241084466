import React from "react"

const quoteOptions = {
  eddm: [
    "6.5 x 9 EDDM Postcard",
    "4.5 x 12 EDDM Postcard",
    "Other EDDM Postcard Size",
  ],
  postcard: [
    "4 x 6 Postcard",
    "5.5 x 8.5 Postcard",
    "6 x 11 Postcard",
    "Other Postcard Size",
  ],
  letter: [
    "8.5 x 11 Letter",
    "8.5 x 14 Letter",
    "11 x 17 Newsletter",
    "Other Letter Size",
  ],
  all: [
    "4 x 6 Postcard",
    "5.5 x 8.5 Postcard",
    "6 x 11 Postcard",
    "Other Postcard Size",
    "6.5 x 9 EDDM Postcard",
    "4.5 x 12 EDDM Postcard",
    "Other EDDM Postcard Size",
    "8.5 x 11 Letter",
    "8.5 x 14 Letter",
    "11 x 17 Newsletter",
    "Other Letter Size",
    "Other"
  ]
}

export default function RequestQuoteForm({ location, service="all" }) {
  return (
    <div className="form-box">
      <div>
        <h3 className="is-size-3 has-text-black mb-1 has-text-centered has-text-weight-bold">
          Request A Quote
        </h3>
      </div>
      <form className="" name={`request-quote-${location}`}>
        <div className="field">
          <label className="label">Name</label>
          <div className="control">
            <input className="input" type="text" placeholder="Name" />
          </div>
        </div>
        <div className="field">
          <label className="label">Email</label>
          <div className="control">
            <input
              className="input"
              type="email"
              placeholder="name@address.com"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Phone Number</label>
          <div className="control">
            <input
              className="input"
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              placeholder="206-123-4567"
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Print and Mail Services</label>
          <div className="select is-fullwidth">
            <select>
              <option>Print and Mail</option>
              <option>Print only</option>
              <option>Mail only</option>
            </select>
          </div>
        </div>
        <div className="field">
          <label className="label">Direct Mail Product</label>
          <div className="select is-fullwidth">
            <select>
              {quoteOptions[service].map(name => (
                <option>{name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="field">
          <label className="label">Quantity</label>
          <div className="control">
            <input className="input" type="number" placeholder="500" />
          </div>
        </div>
        <div className="field">
          <label className="label">Mailing List</label>
          <div className="select is-fullwidth">
            <select>
              <option>I have my own mailing list</option>
              <option>I need a mailing list</option>
            </select>
          </div>
        </div>
        <div className="field">
          <label className="label">Design Services</label>
          <div className="select is-fullwidth">
            <select>
              <option>I have my own design</option>
              <option>I need a design services</option>
            </select>
          </div>
        </div>
        <div className="field">
          <div className="control">
            <button className="button is-bittersweet is-fullwidth has-text-white">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
